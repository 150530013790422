// 判断图片路径是否有效
export const checkImgExists = (imgurl) => {
  return new Promise((resolve, reject) => {
    const ImgObj = new Image()
    ImgObj.src = imgurl
    ImgObj.onload = res => {
      resolve(res)
    }
    ImgObj.onerror = err => {
      reject(err)
    }
  })
}

// 获取地址栏指定参数的值
export const getUrlParam = (paramName) => {
  const url = location.search
  const Params = {}
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1)
    const strArr = str.split('&')
    for (let i = 0; i < strArr.length; i++) {
      Params[strArr[i].split('=')[0]] = unescape(strArr[i].split('=')[1])
    }
  }
  return Params[paramName] || ''
}

// 原理：在规定的时间内没有再次触发，被判定为有效
// debounce(函数名, 防抖时间, true)  防抖
let timeout = null
export const debounce = (func, wait = 500, immediate = false) => {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout)
  // 立即执行，此类情况一般用不到
  if (immediate) {
    var callNow = !timeout
    timeout = setTimeout(function () {
      timeout = null
    }, wait)
    if (callNow) typeof func === 'function' && func()
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(function () {
      typeof func === 'function' && func()
    }, wait)
  }
}

// 原理：规定的时间内只能触发一次 节流
export const throttle = (func, wait) => {
  let context, args
  let previous = 0
  return () => {
    // 当前时间戳
    const now = +new Date() // 相当于 let now = new Date().getTime()
    context = this
    args = arguments
    // 当前的时间戳 - 之前的时间戳
    if (now - previous > wait) {
      func.apply(context, args)
      previous = now
    }
  }
}
