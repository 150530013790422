import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUrlParam } from '@/libs/utils'
import { Dialog } from 'vant'
Vue.use(VueRouter)

// 在vue项目中，如果使用$router.push跳转到一个相同的路由报错 解决方法
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/mine/userInfo',
    name: 'userInfo',
    meta: {
      title: '我的信息'
    },
    component: () => import('@/views/userInfo/index.vue')
  },
  {
    path: '/generatePerson',
    name: 'generatePerson',
    meta: {
      title: '人脸识别'
    },
    component: () => import('@/views/face/index.vue')
  },
  {
    path: '/mine/server',
    name: 'server',
    meta: {
      title: '服务协议'
    },
    component: () => import('@/views/server/index.vue')
  },
  {
    path: '*',
    redirect: '/mine/userInfo'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_API,
  routes
})

router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.path === '/mine/userInfo') {
    const merNo = getUrlParam('merNo')
    const idCard = getUrlParam('idCard')
    if (merNo && idCard) {
      next()
    } else {
      Dialog.alert({
        title: '参数异常',
        message: '未接收到商户号/身份证号'
      }).then(() => {})
    }
  } else {
    next()
  }
})

export default router
